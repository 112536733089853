import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Content from "../components/content"
import { ButtonExt } from "../components/button"
import Intro from "../components/intro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"

import * as styles from "./projects.module.scss"

const ProjectsPage = ({ data }) => {
  const yml = data.allDataYaml.edges[0].node.projects
  return (
    <Layout>
      <Content>
        <Intro title="Projects" description={yml.intro} />
        <ul className={styles.projects}>
          {yml.works.map((item, index) => {
            return (
              <li key={`project_item${index}`}>
                <a href={item.view} target="_blank" rel="noreferrer">
                  <div className={styles.dots}>
                    <GatsbyImage
                      className={styles.image}
                      image={item.path.childImageSharp.gatsbyImageData}
                      alt={item.title}
                    />
                  </div>
                </a>
                <div className={styles.description}>
                  <h3>{item.title}</h3>
                  <small>{item.details}</small>
                  <p>{item.description}</p>
                  <ButtonExt buttonLink={item.view}>
                    <FontAwesomeIcon className={styles.icon} icon={faSearch} />
                    View Project
                  </ButtonExt>
                </div>
              </li>
            )
          })}
        </ul>
      </Content>
    </Layout>
  )
}

export default ProjectsPage

export const query = graphql`
  query Projects {
    allDataYaml(sort: {fields: projects___intro}) {
      edges {
        node {
          projects {
            intro
            works {
              description
              details
              title
              view
              path {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, formats: WEBP)
                }
              }
            }
          }
        }
      }
    }
  }
`
