import React from "react"
import { Link } from "gatsby"

import * as styles from "./button.module.scss"

const Button = props => {
  return (
    <button className={styles.button}>
      <Link to={props.buttonLink}>{props.children}</Link>
    </button>
  )
}

const ButtonExt = props => {
  return (
    <button className={styles.button}>
      <a href={props.buttonLink} target="_blank" rel="noreferrer">
        {props.children}
      </a>
    </button>
  )
}

export { Button, ButtonExt }
