import React from "react"
import Typist from "react-typist"

import * as styles from "./intro.module.scss"

const Intro = props => {
  return (
    <div className={styles.intro}>
      <Typist cursor={{ show: false }}>
        <h1 className={styles.title}>{props.title}</h1>
      </Typist>
      <p className={styles.description}>{props.description}</p>
      {props.children}
    </div>
  )
}

export default Intro
